<template>
  <div class="container">
    <div class="handle-box">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="success" icon="el-icon-plus" @click="onAdd">
        添加
      </el-button>
    </div>
    <!--数据表格-->
    <el-table
      :data="list"
      border
      fit
      highlight-current-row
      @row-dblclick="onEdit"
    >
      <el-table-column label="收件人" prop="recipient" />
      <el-table-column label="部门" prop="departName" />
      <el-table-column label="邮箱" prop="recipientMail" />
      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="small"
            @click="onEdit(scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="onDel(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑信息 -->
    <el-dialog title="编辑信息" width="50%" :visible.sync="selectDlgShow" :close-on-click-modal="false">
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="120px">
          <el-form-item label="接收人" prop="recipient" required>
            <el-input v-model="form.recipient" type="text" clearable />
          </el-form-item>

          <el-form-item label="接收邮箱" prop="recipientMail" required>
            <el-input v-model="form.recipientMail" type="text" clearable />
          </el-form-item>

          <el-form-item label="部门" prop="departId" required>
            <el-cascader
              :model="form.departId"
              filterable
              :options="departmentTreeList"
              :props="{ value: 'id', label: 'name', checkStrictly: true }"
              clearable
              @change="getDepartId"
            ></el-cascader>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button type="primary" @click="handleSave(handleType)">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/common";

export default {
  name: "meituanDepartment",
  data() {
    return {
      form: { departId: "" },
      departmentList: [],
      departmentTreeList: [],
      userList: [],

      mealTimeArr: [
        {
          value: "",
        },
      ],
      handleType: "add",
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
      },
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
    };
  },
  created() {
    this.fetchData();
    this.getTreeList();
  },
  methods: {
    getDepartId(e) {
      this.form.departId = e[e.length - 1];

    },
    async getTreeList() {
      let res = await api.commonPost({
        apiName: "/department/getTreeList",
      });
      this.departmentTreeList = res.data;
      console.log("res", res);
    },
    onRefresh() {
      this.fetchData();
    },

    onDel(id) {
      this.$confirm("您确定要删除此条记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        ids.push(id);
        api
          .commonPost({
            apiName: "/meituanDepartmentApprover/remove",
            ids: ids,
          })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error("删除错误！");
          });
      });
    },

    removeDomain(item) {
      var index = this.mealTimeArr.indexOf(item);
      if (index !== -1) {
        this.mealTimeArr.splice(index, 1);
      }
    },

    addTimeRange() {
      this.mealTimeArr.push({
        value: "",
        key: Date.now(),
      });
    },
    async getUserList(e) {
      let res = await api.commonPost({
        apiName: "/user/userDropDownList?departmentId=" + e,
      });
      this.userList = res.data;
    },

    async handleSave(type) {
      var obj = {};

      if (type == "add") {
        obj = Object.assign({}, this.form, {
          apiName: "/meituanMail/create",
        });
      } else {

        obj = Object.assign({}, this.form, {
          apiName: "/meituanMail/update",
        });
      
      }
      let res = await api.commonPost(obj);
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.selectDlgShow = false;
        this.dlgLoading = false;
        this.fetchData();
      }
    },

    //添加
    onAdd() {
      this.handleType = "add";
      this.form = {
        recipientMail: "",
        departId: [],
        recipient: "",
      };
      this.selectDlgShow = true;
    },
    //编辑
    onEdit(row) {
      this.handleType = "edit";
      this.form = row;
      this.selectDlgShow = true;
    },
    //查询列表
    async fetchData() {
      let res = await api.commonPost({ apiName: "/meituanMail" });
      if (res.code == 200) {
        this.list = res.data.records;
      }
    },

    //获取分局部门列表，用于添加

    async getDepartList() {
      let res = await api.commonPost({ apiName: "/department/getBranchList" });
      this.departmentList = res.data;
    },

    getRangTimeStr(arr) {
      let strArr = [];
      arr.forEach((element) => {
        strArr.push(element.value[0] + "~" + element.value[1]);
      });
      let permission = strArr.join(",");
      return permission;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
</style>
